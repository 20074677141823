import { cn } from '@cardo/lib';
import { useEffect, useRef, useState } from 'react';
import type { ChangeEvent } from 'react';
import {
  IoEyeSharp,
  IoEyeOffSharp,
  IoInformationCircleOutline,
} from 'react-icons/io5';
import { InputLabel } from './InputLabel';
import { Tooltip } from '../common/Tooltip';
import type { FieldProps } from 'remix-validated-form/dist/index.js';

const formatNumber = (num: string): string => {
  // Remove any existing commas and non-numeric characters (except decimal point)
  const cleanNum = num.replace(/[^\d.-]/g, '');
  if (!cleanNum) return '';

  const parsed = parseFloat(cleanNum);
  if (isNaN(parsed)) return '';

  const [integerPart, decimalPart] = cleanNum.split('.');

  // Format integer part with commas
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

export type BaseInputProps = {
  name?: string;
  label?: string | null;
  type: string;
  className?: string;
  autocomplete?: string;
  defaultValue?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  tooltip?: string;
  error?: string | undefined;
  getInputProps?: FieldProps['getInputProps'];
  placeholder?: string;
  leftIcon?: React.ReactNode;
  maxLength?: number;
  readOnly?: boolean;
};

export function BaseInput({
  name,
  label,
  type,
  className,
  autocomplete,
  defaultValue,
  onChange,
  value,
  tooltip,
  error,
  getInputProps,
  placeholder,
  leftIcon,
  maxLength,
  readOnly,
}: BaseInputProps) {
  const ref = useRef<HTMLInputElement>(null);
  // Use text type for number inputs to allow comma formatting
  const [inputType, setInputType] = useState<string>(
    type === 'number' ? 'text' : type
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [displayValue, setDisplayValue] = useState<string>(() => {
    if (type === 'number' && defaultValue) {
      return formatNumber(defaultValue);
    }
    return defaultValue || '';
  });

  const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;

    if (type === 'number') {
      const formatted = formatNumber(rawValue);
      setDisplayValue(formatted);

      const syntheticEvent = {
        ...e,
        target: {
          ...e.target,
          value: rawValue.replace(/,/g, ''),
        },
      };

      onChange?.(syntheticEvent as ChangeEvent<HTMLInputElement>);
    } else {
      onChange?.(e);
    }
  };

  useEffect(() => {
    if (type === 'password') {
      setInputType(showPassword ? 'text' : 'password');
    } else if (type === 'number') {
      setInputType('text'); // Always use text type for number inputs to allow comma formatting
    } else {
      setInputType(type);
    }
  }, [showPassword, type]);

  useEffect(() => {
    if (type === 'number' && value) {
      setDisplayValue(formatNumber(value));
    }
  }, [value, type]);

  const finalValue = type === 'number' ? displayValue : value;

  return (
    <label
      htmlFor={name}
      className={cn(
        'w-full px-px',
        className,
        className?.includes('sm:w-') ? '' : 'sm:w-[384px]'
      )}
    >
      <div className="flex items-end">
        {label && <InputLabel>{label}</InputLabel>}
        {tooltip && (
          <Tooltip content={tooltip} childrenClassName="p-1">
            <IoInformationCircleOutline />
          </Tooltip>
        )}
      </div>
      <div className="relative">
        {type === 'password' && (
          <div className="absolute bottom-0 right-4 top-0 flex items-center justify-center">
            <button
              className="my-auto"
              type="button"
              role="switch"
              aria-checked={showPassword}
              onClick={() => setShowPassword((prev) => !prev)}
              tabIndex={-1}
            >
              {showPassword ? (
                <IoEyeSharp className="text-xl text-gray-600" />
              ) : (
                <IoEyeOffSharp className="text-xl text-gray-600" />
              )}
            </button>
          </div>
        )}
        {leftIcon && (
          <div className="absolute left-4 flex h-full items-center">
            {leftIcon}
          </div>
        )}
        {type === 'number' && (
          <input
            type="hidden"
            value={finalValue?.replace(/,/g, '') || ''}
            name={name}
          />
        )}
        <input
          ref={ref}
          type={inputType}
          className={cn(
            `w-full rounded-xl py-3 pl-5 pr-5 border-gray-500`,
            type === 'file' && 'cursor-pointer border hover:bg-gray-50',
            type === 'password' && 'pr-12',
            leftIcon && 'pl-8',
            readOnly && 'bg-gray-200'
          )}
          autoComplete={autocomplete}
          defaultValue={defaultValue || undefined}
          onChange={handleNumberChange}
          value={finalValue}
          {...(typeof getInputProps === 'function'
            ? {
                ...getInputProps({
                  id: name,
                  onChange: handleNumberChange,
                }),
                name: type === 'number' ? `${name}-display` : name,
              }
            : {})}
          placeholder={placeholder}
          onFocus={() => {
            ref.current?.select();
          }}
          maxLength={maxLength}
          readOnly={readOnly}
          disabled={readOnly}
        />
      </div>
      {!error && <p className="h-7"></p>}
      {error && (
        <p className="whitespace-wrap my-1 text-sm text-rose-600">{error}</p>
      )}
    </label>
  );
}
